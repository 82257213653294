<template>
  <!-- 资料库>新增资料 -->
  <div class="dict" v-loading.fullscreen.lock="loadingDetail">
    <el-page-header
      style="margin-bottom: 1.5625vw"
      @back="goBack"
      content="资料管理/查看资料"
    >
      <template slot="title"> <i class="el-icon-arrow-left"></i>返回 </template>
    </el-page-header>
    <el-radio-group
      v-model="tabPosition"
      style="margin-bottom: 1.5625vw"
      @change="changeManageType"
    >
      <el-radio-button label="info">资料信息</el-radio-button>
      <el-radio-button label="versionNumber">版本</el-radio-button>
      <el-radio-button label="log">操作日志</el-radio-button>
    </el-radio-group>

    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
      v-if="tabPosition == 'info'"
    >
      <el-form-item label="上级目录：" prop="directoryId">
        <el-cascader
          disabled
          :show-all-levels="false"
          style="width: 100%"
          v-model="form.directoryId"
          :options="directoryList"
          :props="treeProps"
        ></el-cascader>
        <!-- <el-select v-model="form.directoryId" disabled placeholder="请选择">
          <el-option
            v-for="(item, index) in directoryList"
            :key="index"
            :label="item.directoryName"
            :value="item.id"
          ></el-option>
        </el-select> -->
      </el-form-item>

      <el-form-item label="文件名称：" prop="fileName">
        <el-input v-model="form.fileName" disabled></el-input>
      </el-form-item>
      <el-form-item label="版本号：" prop="versionNumber">
        <el-input v-model="form.versionNumber" disabled></el-input>
      </el-form-item>
      <el-form-item label="文件摘要：" prop="summary">
        <el-input type="textarea" v-model="form.summary" disabled></el-input>
      </el-form-item>
      <el-form-item label="权限：" prop="authority">
        <el-checkbox-group
          disabled
          v-model="form.authority"
          @change="changeAuthority"
        >
          <el-checkbox label="1" name="type">在线查看</el-checkbox>
          <el-checkbox label="2" name="type">下载为PDF</el-checkbox>
          <el-checkbox label="3" name="type">下载为原文件</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="附件类型：" prop="type">
        <el-radio-group disabled v-model="form.type">
          <el-radio label="1">文件</el-radio>
          <el-radio label="2">视频</el-radio>
          <el-radio label="3">图片</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="附件信息：" prop="fileList">
        <el-table
          :header-cell-style="{
            background: '#F1F4F9',
            color: '#5E6D9B',
            fontWeight: '400',
          }"
          :data="form.dataAttachmentList"
          v-loading="downLoading"
        >
          <!-- <el-table-column property="type" label="类型">
            <template slot-scope="scope">
              <i class="el-icon-folder-opened" v-if="scope.row.type == 1"
                >文档</i
              >
              <i
                class="el-icon-video-camera-solid"
                v-else-if="scope.row.type == 2"
                >视频</i
              >
              <i class="el-icon-question" v-else>其他</i>
            </template>
          </el-table-column> -->
          <el-table-column
            property="attachmentName"
            label="附件名称"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.attachmentName }}.{{ scope.row.attachmentType }}
            </template>
          </el-table-column>
          <el-table-column property="size" label="附件大小" align="center">
            <template slot-scope="scope">
              <!-- {{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB -->
              <span v-if="1048576 > scope.row.size"
                >{{ (scope.row.size / 1024).toFixed(2) }}KB</span
              >
              <span v-else
                >{{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB</span
              >
            </template></el-table-column
          >
          <el-table-column
            property="name"
            width="200"
            label="下载"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  size="mini"
                  type="text"
                  v-if="form.authority && form.authority.indexOf('1') != -1"
                  @click="showFile(scope.row)"
                  ><span style="color: #409eff">查看</span></el-button
                >
                <el-button
                  size="small"
                  type="text"
                  v-if="form.authority && form.authority.indexOf('2') != -1"
                  @click="downLoadFile(scope.row.id, 'pdf')"
                  ><span
                    :style="form.type == 2 ? 'color:#999' : 'color: #409eff'"
                    >下载PDF</span
                  ></el-button
                >
                <el-button
                  size="small"
                  type="text"
                  v-if="form.authority && form.authority.indexOf('3') != -1"
                  @click="downLoadFile(scope.row.id, 'file')"
                  ><span style="color: #409eff">下载原文件</span></el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div v-if="tabPosition == 'versionNumber'">
      <l-table
        :columns="columns1"
        :dataSource="versionNumberData"
        :pagination="pageFlag"
        :ipagination="page"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :loading="tableLoading"
        :showXh="false"
        :height="'calc(100% - 2.91667vw)'"
        :autoHeight="true"
      >
        <el-table-column
          prop="fileName"
          label="文件名称"
          align="center"
          slot="fileName"
        ></el-table-column>
        <el-table-column
          prop="versionNumber"
          slot="versionNumber"
          min-width="250"
          label="版本号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="updateTime"
          slot="updateTime"
          min-width="250"
          label="更新时间"
          align="center"
        ></el-table-column>
        <!-- type="expand" -->
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.createName }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          slot="handle"
          align="center"
          min-width="250"
          fixed="right"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                size="small"
                type="text"
                @click="showAttachment(scope.row.id)"
                ><span>查看附件</span>
              </el-button>
              <el-button
                v-if="scope.row.currentVersion == '0'"
                size="small"
                type="text"
                @click="getFileInfoByVersionId(scope.row.id)"
                ><span>回滚</span></el-button
              >
              <!-- @click="getManageInfo(scope.row.id, true)" -->
              <el-button
                v-else-if="scope.row.currentVersion == '1'"
                size="small"
                type="text"
                ><span class="lightColor">当前版本</span></el-button
              >
              <el-button
                size="small"
                type="text"
                @click="showAttachment(scope.row.id)"
                ><span>下载</span></el-button
              >
            </div>
          </template>
        </el-table-column>
      </l-table>
    </div>
    <div v-if="tabPosition == 'log'">
      <el-tabs v-model="activeName" @tab-click="changeActiveName">
        <el-tab-pane label="全部" name=""></el-tab-pane>
        <el-tab-pane label="已阅" name="1"></el-tab-pane>
        <el-tab-pane label="编辑" name="2"></el-tab-pane>
        <el-tab-pane label="下载" name="3"></el-tab-pane>
      </el-tabs>
      <l-table
        :columns="columns2"
        :dataSource="logData"
        :pagination="pageFlag"
        :ipagination="page"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :loading="downLoading"
        :showXh="false"
        :height="'calc(100%)'"
        :autoHeight="true"
      >
        <el-table-column
          prop="operationTime"
          label="操作日期"
          align="center"
          min-width="150"
          slot="operationTime"
        ></el-table-column>
        <el-table-column
          prop="operatorName"
          label="操作者"
          align="center"
          min-width="200"
          slot="operatorName"
        ></el-table-column>
        <el-table-column
          prop="operationType"
          label="操作类型"
          align="center"
          min-width="150"
          slot="operationType"
        >
          <template slot-scope="scope">{{ scope.row.operationType }}</template>
        </el-table-column>
        <el-table-column
          prop="versionNumber"
          label="版本号"
          align="center"
          min-width="200"
          slot="versionNumber"
        ></el-table-column>
        <el-table-column
          prop="attachmentName"
          label="附件名称"
          align="center"
          min-width="200"
          slot="attachmentName"
        >
          <template slot-scope="scope">
            {{ scope.row.attachmentName }}
          </template>
        </el-table-column>
      </l-table>
    </div>
    <el-dialog title="查看附件" :visible.sync="attachmentVisible">
      <el-table
        :header-cell-style="{
          background: '#F1F4F9',
          color: '#5E6D9B',
          fontWeight: '400',
        }"
        :data="attachmentData"
      >
        <el-table-column
          align="center"
          min-width="100"
          property="type"
          label="类型"
        >
          <template slot-scope="scope">
            <span class="fileType" v-if="scope.row.type == 1">
              <img
                v-if="
                  scope.row.attachment_type.indexOf('xl') != -1 ||
                  scope.row.attachment_type.indexOf('XL') != -1
                "
                src="@/assets/img/board/excel.png"
                alt=""
              />
              <img
                v-if="
                  scope.row.attachment_type.indexOf('doc') != -1 ||
                  scope.row.attachment_type.indexOf('DOC') != -1
                "
                src="@/assets/img/board/word.png"
                alt=""
              />
              <img
                v-if="
                  scope.row.attachment_type.indexOf('pdf') != -1 ||
                  scope.row.attachment_type.indexOf('PDF') != -1
                "
                src="@/assets/img/board/pdf.png"
                alt=""
              />
              <img
                v-if="
                  scope.row.attachment_type.indexOf('pp') != -1 ||
                  scope.row.attachment_type.indexOf('PP') != -1
                "
                src="@/assets/img/board/ppt.png"
                alt=""
              />
              文档</span
            >
            <i class="fileType" v-else-if="scope.row.type == 2"
              ><img src="@/assets/img/board/video.png" alt="" />视频</i
            >
            <i class="fileType" v-else-if="scope.row.type == 3"
              ><img src="@/assets/img/board/img.png" alt="" />图片</i
            >
            <i class="el-icon-question" v-else>其他</i>
          </template>
        </el-table-column>
        <el-table-column
          property="attachment_name"
          align="center"
          min-width="150"
          label="附件名称"
        >
          <template slot-scope="scope"
            >{{ scope.row.attachment_name }}.{{
              scope.row.attachment_type
            }}</template
          >
        </el-table-column>
        <el-table-column
          min-width="100"
          align="center"
          property="size"
          label="附件大小"
        >
          <template slot-scope="scope">
            <!-- {{ (scope.row.size / 1024).toFixed(2) }}MB -->
            <span v-if="1048576 > scope.row.size"
              >{{ (scope.row.size / 1024).toFixed(2) }}KB</span
            >
            <span v-else
              >{{ (scope.row.size / 1024 / 1024).toFixed(2) }}MB</span
            >
          </template>
        </el-table-column>
        <el-table-column
          property="name"
          align="center"
          label=""
          min-width="250"
        >
          <template slot="header">
            <!-- <el-button v-if="attachmentData && attachmentData[0] && attachmentData[0].authority != 1" @click="downLoadFile(null,'pack')" type="text">打包下载</el-button> -->
            <el-button
              v-if="
                attachmentData &&
                attachmentData[0] &&
                attachmentData[0].authority == '1,2'
              "
              @click="downLoadFile(null, 'pack2')"
              type="text"
              >打包下载</el-button
            >
            <el-button
              v-if="
                attachmentData &&
                attachmentData[0] &&
                attachmentData[0].authority == '1,2,3'
              "
              @click="downLoadFile(null, 'pack1')"
              type="text"
              >打包下载</el-button
            >
            <!-- <el-button @click="downLoadFile(null,'pack')" type="text">打包下载</el-button> -->
          </template>
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                v-if="
                  scope.row.authority && scope.row.authority.startsWith('1')
                "
                type="text"
                @click="showFile(scope.row)"
                ><span style="color: #409eff">查看</span></el-button
              >
              <el-button
                v-if="
                  scope.row.authority && scope.row.authority.startsWith('1,2,3')
                "
                size="small"
                type="text"
                @click="downLoadFile(scope.row.id, 'file')"
                ><span style="color: #409eff">下载原文件</span></el-button
              >
              <el-button
                v-if="
                  scope.row.authority && scope.row.authority.startsWith('1,2')
                "
                size="small"
                type="text"
                @click="downLoadFile(scope.row.id, 'pdf')"
                ><span
                  :style="scope.row.type == 2 ? 'color:#999' : 'color: #409eff'"
                  >下载PDF</span
                ></el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <onlinePreview
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="
        (fileAttachment.attachment_name || '') +
        '.' +
        (fileAttachment.attachment_type || '')
      "
    />
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import downloadFile from "@/utils/downloadFile.js";
import onlinePreview from "@/components/onlinePreview/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      fileAttachment: {},
      onlinePreviewVisible: false,
      tabPosition: "info",
      versionNumberData: [],
      tableLoading: false,
      attachmentVisible: false,
      attachmentData: [],
      logData: [],
      downLoading: false,
      dataAttachmentList: [],
      activeName: "",
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns1: [
        {
          label: "文件名称",
          slotName: "fileName",
        },
        {
          label: "版本号",
          slotName: "versionNumber",
        },
        {
          label: "更新时间",
          slotName: "updateTime",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      columns2: [
        {
          label: "操作日期",
          slotName: "operationTime",
        },
        {
          label: "操作者",
          slotName: "operatorName",
        },
        {
          label: "操作类型",
          slotName: "operationType",
        },
        {
          label: "版本号",
          slotName: "versionNumber",
        },
        {
          label: "附件名称",
          slotName: "attachmentName",
        },
      ],
      form: {
        authority: [],
        directoryId: null,
        summary: "",
        fileName: "",
        type: null,
        versionNumber: "1.0",
      },
      type: null,
      directoryList: [],
      attachmentList: [],
      rules: {
        directoryId: [
          { required: true, message: "请选择上级目录", trigger: "blur" },
        ],
        summary: [
          { required: true, message: "请输入文件摘要", trigger: "blur" },
        ],
        versionNumber: [
          { required: true, message: "请选择上级目录", trigger: "blur" },
        ],
        fileName: [
          { required: true, message: "请输入文件名称", trigger: "blur" },
        ],
        authority: [{ required: true, message: "请选择权限", trigger: "blur" }],
        type: [{ required: true, message: "请选择附件类型", trigger: "blur" }],
        fileList: [{ required: true, message: "请上传附件", trigger: "blur" }],
      },
      fileList: [],
      treeProps: {
        value: "id",
        label: "directoryName",
        children: "child",
      },
      loadingDetail: false,

      labelWidth: "120px",
    };
  },
  components: {
    LTable,
    onlinePreview,
  },
  // beforeRouteUpdate(to, from, next) {
  //   if (to.query.id) {
  //     next();
  //     this.id = to.query.id;
  //     if (to.query.currentVersion) {
  //       this.getFileInfoByVersionId();
  //     } else {
  //       this.getManageInfo();
  //     }
  //   } else {
  //     this.$message.error("id不存在");
  //     this.goBack();
  //   }
  // },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.query.id) {
        vm.id = to.query.id;
        if (to.query.currentVersion) {
          vm.currentVersion = true;
          vm.getFileInfoByVersionId();
        } else {
          vm.currentVersion = false;
          vm.getManageInfo();
        }
      } else {
        vm.$message.error("id不存在");
        vm.goBack();
      }
    });
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.getThirdDirectoryList();
  },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    getThirdDirectoryList() {
      this.$api.getThirdDirectory().then((res) => {
        if (res.data.code == 0) {
          this.directoryList = res.data.data;
          this.parentList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    changeType(type) {
      if (this.form.type) {
        if (this.fileList.length) {
          this.$confirm(
            "切换附件上传类型会清空已上传的文件, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              customClass: "addChild",
            }
          )
            .then(() => {
              this.fileList = [];
            })
            .catch(() => {
              this.type = this.form.type;
            });
        } else {
          this.form.type = type;
        }
      } else {
        this.form.type = type;
      }
    },
    changeAuthority() {
      console.log(this.form.authority);
    },
    changeFileType(val) {
      console.log(val);
      if (val == 2) {
        this.form.authority = [];
      }
    },
    editOrShow(type, id) {
      /** 编辑或者查看信息 */
      this.$router.push({
        path: type == "show" ? "showManage" : "editManage",
        query: { id },
      });
    },
    showAttachment(id) {
      //查看附件
      this.$api.getAttachmentVersionInfo({ id }).then((res) => {
        if (res.data.code == "0" && res.data.data && res.data.data.length) {
          this.attachmentData = res.data.data;
          this.attachmentVisible = true;
          console.log(this.attachmentData);
        } else {
          this.$message.error("附件为空");
        }
      });
    },
    changeManageType(stamp) {
      console.log(this.tabPosition, "type");
      switch (this.tabPosition) {
        case "info":
          this.getManageInfo();
          break;
        case "versionNumber":
          this.getVersionNumberList();
          break;
        case "log":
          this.getLogList(this.activeName);

          break;
        default:
          break;
      }
      if (stamp) return;
      this.page = {
        num: 1,
        size: 10,
        total: 0,
      };
    },
    getVersionNumberList() {
      let params = Object.assign(
        { fileId: this.id },
        {
          current: this.page.num,
          size: this.page.size,
          fileName: this.form.keyword,
        }
      );
      this.tableLoading = true;
      this.$api.getVersionNumberLog(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.versionNumberData = res.data.data.records;
          this.page.total = res.data.data.total;
          if (res.data.data.total >= 1) {
            this.pageFlag = true;
          } else {
            this.pageFlag = false;
          }
        } else {
          this.versionNumberData = [];
          this.page.total = 0;
          this.pageFlag = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    getLogList(type) {
      let params = Object.assign(
        { fileId: this.id },
        {
          current: this.page.num,
          size: this.page.size,
          fileName: this.form.keyword,
        }
      );
      this.tableLoading = true;
      type ? (params.type = type) : null;
      this.$api.getFileHandleLog(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.logData = res.data.data.records;
          this.page.total = res.data.data.total;
          if (res.data.data.total >= 1) {
            this.pageFlag = true;
          } else {
            this.pageFlag = false;
          }
        } else {
          this.logData = [];
          this.page.total = 0;
          this.pageFlag = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    getManageInfo(id) {
      // console.log(this.currentVersion,this.$route.query.id,'this.currentVersion')
      if (this.$route.query.currentVersion) {
        this.getFileInfoByVersionId(this.$route.query.id);
        return;
      }
      let params = { id: id || this.$route.query.id };
      this.tabPosition = "info";
      this.loadingDetail = true;
      this.$api.getFileInfo(params).then((res) => {
        this.loadingDetail = false;
        if (res.data.code == 0 && res.data.data) {
          this.form = res.data.data;
          this.form.authority = this.form.authority.split(",");
          if (this.form.dataAttachmentList) {
            this.dataAttachmentList = this.form.dataAttachmentList;
          }
        } else {
          this.$message.error(res.data.msg);
          // this.goBack();
        }
      });
    },
    getFileInfoByVersionId(fileId) {
      // let id = fileId || this.$route.query.id;
      // console.log(fileId)

      this.$router.push({
        path: "/createManage",
        query: { id: fileId, isHuiGun: 1 },
      });
      // this.$api.getFileInfoByVersionId({ id:id }).then((res) => {
      //   if (res.data.code == "0" && res.data.data) {
      //     this.$router.push({
      //       path: "showManage",
      //       query: {
      //         id,
      //         currentVersion: "1",
      //       },
      //     });
      //     this.tabPosition = "info";
      //     this.form = res.data.data;
      //     this.id = id;
      //     this.form.authority = this.form.authority.split(",");
      //     this.dataAttachmentList = this.form.dataAttachmentList || [];
      //     // this.currentVersion = true
      //     // console.log(this.currentVersion,'/*/*/*')
      //   } else {
      //     this.$message.error(res.data.msg || "该数据不存在");
      //   }
      // });
    },
    changeActiveName(tab) {
      //发出请求
      this.getLogList(tab.name);
    },
    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },
    showFile(item) {
      if (item.type == "2") {
        this.onlinePreviewVisible = true;
        this.fileAttachment = item;
        this.fileAttachment.previewType = "video";
      } else if (item.type == "3") {
        this.onlinePreviewVisible = true;
        this.fileAttachment = item;
        this.fileAttachment.previewType = "image";
      } else if (item.type == "1") {
        this.downLoading = true;
        if (item.pdfUrl || item.pdf_url) {
          this.onlinePreviewVisible = true;
          this.fileAttachment = {
            previewType: "pdf",
            url: item.pdfUrl || item.pdf_url,
            attachment_name: item.attachmentName,
            attachment_type: item.attachmentType,
          };
          this.downLoading = false;
        } else {
          this.$message.error("暂不支持在线预览，请下载后查看");
          return;
        }
        // this.onlinePreviewVisible = true;
        // this.fileAttachment = item;
        // this.fileAttachment.previewType = item.attachment_type;
      } else {
        this.$message.error("错误");
      }
      this.$api.onlinePreview({ id: item.id });
    },
    downLoadFile(id, type) {
      //下载文件
      if (this.form.type == 2 && type == "pdf") {
        return;
      }
      let url = {
        file:
          "/dataDirectory/data-attachment/downloadFile?id=" + id + "&type=1",
        pack1:
          "/dataDirectory/data-attachment/batchDownloadFile?id=" +
          this.$route.query.id +
          "&type=1",
        pack2:
          "/dataDirectory/data-attachment/batchDownloadFile?id=" +
          this.$route.query.id +
          "&type=2",
        pdf: "/dataDirectory/data-attachment/downloadFile?id=" + id + "&type=2",
      };
      if (!url[type]) {
        this.$message.error("暂无接口");
        return;
      }
      this.downLoading = true;
      downloadFile({ url: url[type] }).then((res) => {
        console.log(res, "-----");
        this.downLoading = false;
      });
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.page.num = val;
      this.changeManageType(true);
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.num = 1;
      this.changeManageType(true);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      console.log(e);
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100%);
  }
}
.lightColor {
  color: #999;
}
.fileType {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 10px;
  }
}
</style>
<style>
.addChild .el-button {
  width: 96px;
  /* height: 38px; */
}
</style>
